/*
Colores
*/
$verde: #5bc504;
$gris: #6a6a6a;
$blanco: #fff;
$negro: #101010;
$color-hipervinculo: #27a801;

/*
Texto
*/
$fontSizeBody: 12px !important;
$fontSizeTitulo: 15px !important;
$fontsizesubitulo: 13px !important;

$fontPrincipal: 'Poppins' !important;

/*
Inputs - botones*/
$borderRadius: 17px;

/*
Paneles */
$borderRadiusPanel: 25px !important;

//Colores Generales
$ica-backgroung: #000000;
$ica-primary: #70be01;
$ica-secondary: #5e5e5e;
$ica-textsidebar: #ffffff;
$ica-componettotales: #ececec;
$ica-topost: #01beb3;
$ica-third: #000000;
$ica-cars-sold: rgb(68 64 60);
$ica-cars-offers: rgb(74 222 128);
